import { NextApi } from '../../../redux/service/Next/next';

export const VERSIONS_MANAGEMENT_GET_URL = 'VERSIONS_MANAGEMENT_GET_URL';
export const VERSIONS_MANAGEMENT_GET_FIRMWARE_LIST = 'VERSIONS_MANAGEMENT_GET_FIRMWARE_LIST';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR';
export const TURN_OFF_CLEAR_FORM_FIELDS = 'TURN_OFF_CLEAR_FORM_FIELDS';
export const VERSIONS_MANAGEMENT_API_COMPLETE_UPLOAD = 'VERSIONS_MANAGEMENT_API_COMPLETE_UPLOAD'

const VERSIONS_MANAGEMENT_API_FIRMWARE_LIST_URL_PATH = '/whitelabel/version-management/get-filtered-firmware-list';
const VERSIONS_MANAGEMENT_API_URL_PATH = '/whitelabel/version-management/get-url';
const VERSIONS_MANAGEMENT_API_COMPLETE_UPLOAD_PATH = '/whitelabel/version-management/complete-upload';

export const getUrlToUploadFileToS3Next = new NextApi(VERSIONS_MANAGEMENT_API_URL_PATH, VERSIONS_MANAGEMENT_GET_URL, {
  method: 'POST', json: true,
});
export const getFirmwareListNext = new NextApi(VERSIONS_MANAGEMENT_API_FIRMWARE_LIST_URL_PATH, VERSIONS_MANAGEMENT_GET_FIRMWARE_LIST, {
  method: 'GET', json: false,
});
export const completeUploadToS3Next = new NextApi(VERSIONS_MANAGEMENT_API_COMPLETE_UPLOAD_PATH, VERSIONS_MANAGEMENT_API_COMPLETE_UPLOAD, {
  method: 'PUT', json: true,
})
