import {getDeviceLogsByDeviceIdNext, OPEN_DEVICE_LOGS_MODAL} from "./actionType";

const initialState = {
  list:null,
  isLoading:true,
  isOpenModal:false,
}

export const reducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case getDeviceLogsByDeviceIdNext.INIT:
      return {...state, isLoading: true, list: null}
    case getDeviceLogsByDeviceIdNext.SUCCESS:
      return {...state, isLoading: false, list: payload}
    case getDeviceLogsByDeviceIdNext.ERROR:
      return {...state, isLoading: false}
    case OPEN_DEVICE_LOGS_MODAL:
      return {...state, isOpenModal: !state.isOpenModal}
    default:
      return state
  }
}
