import {
  getTokenNext,
  refreshTokenNext,
  validateTokenNext,
} from "./actionTypes";
import {setValuesToLocalStorage} from "./actions";
import {redirectToLoginUrl} from "../../../service/utils/keycloak/utilFunstions";

const initialState = {
  isLoading: false,
  errorOnFetch: false,
  valid: false,
  data: null,
  access_token: null,
}
export const reducer = (state = initialState, {type, ...rest}) => {
  const {payload} = {...rest}
  switch (type) {
    case refreshTokenNext.INIT:
    case validateTokenNext.INIT:
      return state
    case getTokenNext.INIT:
      return {...state, isLoading: true}
    //=======
    case refreshTokenNext.SUCCESS:
      return state
    case validateTokenNext.SUCCESS:
      return {...state, data: payload, valid: true}
    case getTokenNext.SUCCESS:
      setValuesToLocalStorage(payload)
      return {...state, isLoading: false, access_token: payload?.access_token}
    //=======
    case validateTokenNext.ERROR:
      return state
    case getTokenNext.ERROR:
      redirectToLoginUrl()
      return state
    case refreshTokenNext.ERROR:
      return {...state, valid: false}
    default:
      return state
  }
}
