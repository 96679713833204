import React from 'react';
import {CToaster, CToast, CToastBody} from "@coreui/react";
import classNames from "classnames";
import './IensoToaster.scss'

export const IensoToaster =React.memo( ({messagesArr}) => {
  return (
    <div className={'ienso-toaster'}>
      <CToaster
        position={'bottom-right'}
      >{messagesArr?.map((toast, index) => {
        return (
          <CToast
            className={classNames('ienso-toaster__toast', toast?.color)}
            key={'toast' + index}
            show={true}
            autohide={2500}
            fade={toast?.fade}
          >
            <CToastBody>{toast?.text}</CToastBody>
          </CToast>
        )
      })}
      </CToaster>
    </div>
  )
})

