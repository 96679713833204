import {
  getPackageListNext,
} from "./actionType";

const initialState = {
  isLoading: true,
  list: null,
}

export const reducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case getPackageListNext.SUCCESS:
      return {...state, list: payload?.list, isLoading: false}
    case getPackageListNext.ERROR:
      return {...state, isLoading: false, list: null, totalItems: null}
    default:
      return state
  }
}
