import React from 'react';
import {CCol, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CRow} from "@coreui/react";
import './IensoTableActionsButton.scss'
import PropTypes from "prop-types";
import {CButton} from "@coreui/react";
import {BsThreeDotsVertical, BsTrash} from "react-icons/all";


export const IensoTableActionsButton = ({actions}) => {
  if(actions.length ===1 && actions[0].key ==='delete'){
    return (
      <div className={'ienso-table-actions-button-delete'}>
        <CButton onClick={actions[0].func}><BsTrash/></CButton>
      </div>
    )
  }else {
    return (
      <div className={'ienso-table-actions-button'}>
        <CRow>
          <CCol sm={12} className={'btn-container'} md={12} lg={12} xl={12}>
            <CDropdown className="m-1 d-inline-block dropdown">
              <CDropdownToggle color="secondary">
               <BsThreeDotsVertical/>
              </CDropdownToggle>
              <CDropdownMenu placement="left" className={'actions-table-dropdown'}>
                <CDropdownItem
                  header
                  tag="div"
                  className="text-left menu-header"
                  color="light"
                  disabled
                >
                  <div className={'text-body-2'}>Actions:</div>
                </CDropdownItem>
                {actions?.map(action => (
                  <CDropdownItem key={action?.text} onClick={action?.func}>
                    {action?.icon}
                    <span className={'item-custom-text text-body-3'}>{action?.text}</span>
                  </CDropdownItem>))}
              </CDropdownMenu>
            </CDropdown>
          </CCol>
        </CRow>
      </div>
    );
  };
  }



IensoTableActionsButton.propTypes = {
  action: PropTypes.arrayOf(PropTypes.shape({
    func: PropTypes.func,
    text: PropTypes.string
  }))
}
