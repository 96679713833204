import { NextApi } from "../../../redux/service/Next/next";

export const DEVICES_GET = "DEVICES_GET";
export const ADD_NEW_DEVICE = "ADD_NEW_DEVICE";
export const DELETE_DEVICE_BY_ID = "DELETE_DEVICE_BY_ID";
export const SELECT_DEVICE = "SELECT_DEVICE";
export const EXPORT_FILE_INIT = "EXPORT_FILE_INIT";
export const EXPORT_FILE_SUCCESS = "EXPORT_FILE_SUCCESS";
export const EXPORT_FILE_ERROR = "EXPORT_FILE_ERROR";

export const getDevicesNext = new NextApi(
  "device-manager/operator/devices",
  DEVICES_GET,
  {
    method: "GET",
    json: false,
  }
);
export const addNewDeviceNext = new NextApi(
  "device-manager/operator/________",
  ADD_NEW_DEVICE,
  {
    method: "POST",
    json: false,
  }
);

export const deleteDeviceByIdNext = new NextApi(
  "device-manager/operator/{deviceId}",
  DELETE_DEVICE_BY_ID,
  {
    method: "DELETE",
    json: false,
  }
);
