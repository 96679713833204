import {deleteOperatorNext, SELECT_OPERATOR, SET_OPERATOR_INFO} from './actionType';


const initialState = {
  selectedId: null,
  selectedOperator: {
    firstName: '',
    lastName: '',
    email: '',
    enabled: false,
    operatorRoles: {}
  }
}

export const reducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case deleteOperatorNext.SUCCESS:
      return state;
    case deleteOperatorNext.ERROR:
      return state
    case SELECT_OPERATOR:
      return {...state, selectedId: payload}
      case SET_OPERATOR_INFO: {
        return {
          ...state,
          selectedOperator: { ...payload }
        }
      }
    default:
      return state
  }
}
