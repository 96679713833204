import './Pagination.scss'
import React from 'react';
import PropTypes from "prop-types";
import {CPagination} from "@coreui/react";
import classnames from "classnames";
import Translate from "src/redux/service/i18n";

export const Pagination = React.memo(({currentPage, totalItems, onActivePageChange, limit}) => {
  
  const onActivePageChangeController = (page) => {
      onActivePageChange(page, 'page')
    }

  const getPages = () => Math.ceil(totalItems / limit);

  return (
    <div className={classnames('pagination-wrapper')}>
      <CPagination
        activePage={currentPage || 1}
        previousButton={<Translate textKey='IensoTableFooter.Previous'/>}
        nextButton={<Translate textKey='IensoTableFooter.Next'/>}
        doubleArrows={false}
        pages={getPages()}
        onActivePageChange={onActivePageChangeController}
      />
    </div>
  )
})

Pagination.propTypes = {
  currentPage: PropTypes.number,
  totalItems: PropTypes.number,
  onClick: PropTypes.func
}
Pagination.defaultProps = {
  currentPage: 1,
  totalItems: 0,
  onClick: () => undefined
}

