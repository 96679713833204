export const whitelabelInfoSelector = state => state?.realmInfo?.whitelabelInfo
export const realmNameSelector= state => state?.realmInfo?.realmName
export const displayNameSelector= state => state?.realmInfo?.displayName
export const appConfigErrorSelector= state => state?.realmInfo?.isError
export const isLoadingRealmNameSelector = state => state?.realmInfo?.isLoading
export const availableOperatorRolesSelector = state => state?.realmInfo?.availableOperatorRoles
export const buttonColorSelector = state => state?.realmInfo?.whitelabelInfo?.buttonColor
export const brandColorSelector = state =>  state?.realmInfo?.whitelabelInfo?.brandColor
export const languagesListSelector = state =>  state?.i18n?.languages
export const selectedLanguageSelector = state => state?.realmInfo?.whitelabelInfo?.language;
