import urljoin from 'url-join';
import {localStorageConfig} from "../../../config";

export default class Next {
  constructor(url, seed, options = {}) {
    if (!seed || !url) {
      throw new Error('Seed and URL are required');
    }
    this.seed = `${seed}`;
    // this.uri = urljoin('https://api.internal.ienso-dev.com/whiteLabelA/api/', url);
    this.uri = urljoin(process.env.REACT_APP_API_BASE_URL, url);
    //  this.uri = 'https://whiteLabelA.ienso-dev.com'
    //this.uri = urljoin("", url);
    this.options = {
      json: true,
      ...options,
      headers: {
        'Content-Type': 'application/json',
        ...(options.headers || {}),
      },
    };
  }

  get INIT() {
    return `${this.seed}_INIT`;
  }

  get SUCCESS() {
    return `${this.seed}_SUCCESS`;
  }

  get FINALLY() {
    return `${this.seed}_FINALLY`;
  }

  get ERROR() {
    return `${this.seed}_ERROR`;
  }
}

export class NextAuth extends Next{
  constructor(url, seed, options = {}) {
    super(url, seed, options);
    this.uri = urljoin(process.env.REACT_APP_KEYCLOAK_BASE_URL, url);
    this.options = {
      ...this.options,
      params: { realmName: localStorage.getItem(localStorageConfig.REALM_NAME)}
    }
  }
}

export class NextApiMock extends Next{
  constructor(url, seed, options = {}) {
    super(url, seed, options);
    this.uri = urljoin('http:localhost:3000', url);
    this.options = {
      ...this.options,
    }
  }
}

export class NextApi extends Next{
  constructor(url, seed, options = {}) {
    super(url, seed, options);
    this.uri = urljoin(process.env.REACT_APP_API_BASE_URL, url);
    this.options = {
      ...this.options,
    }
  }
}
