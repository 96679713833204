import { NextApi } from 'src/redux/service/Next/next';

export const SUBSCRIPTION_EVENTS = 'SUBSCRIPTION_EVENTS';
export const MODAL_SUBSCRIPTION_EVENTS = 'MODAL_SUBSCRIPTION_EVENTS';

export const getSubscriptionEventsNext = new NextApi(`analytics/package-events`, SUBSCRIPTION_EVENTS, {
  method: 'GET', json: false,
});

export const getModalSubscriptionEventsNext = new NextApi(`analytics/package-events`, MODAL_SUBSCRIPTION_EVENTS, {
  method: 'GET', json: false,
});
