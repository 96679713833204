import React from 'react';
import './IensoTableBody.scss'
import classnames from "classnames";
import {CDataTable} from "@coreui/react";

const IensoTableBody = ({
                          children,
                          onActivePageChange,
                          currentPage,
                          totalItems,
                          csvButtonExportText,
                          csvButtonImportText,
                          onFilterChange,
                          onSorterChange,
                          fields,
                          items,
                          isClickableRows,
                          ...props
                        }) => {
  const childrenArray = Array.isArray(children) ? children : (children ? [children] : []);
  
  const coreUiFields = Object.keys(fields)
    .filter((key) => !fields[key].isCollapse)
    .map((key) => {
      return {
        sorter: false,
        ...fields[key],
        key,
      };
    });

  const defaultColHandle = {
    ...Object.keys(fields).reduce(
        (res, key) => {
          const fieldConfig = fields[key] || {};
          if (fieldConfig.render) {
            res[key] = fieldConfig.render;
          } else {
            res[key] = (row) => (<td className={fieldConfig?.className}>{row[key] || '-'}</td>);
          }

          return res;
        },
        {}
      )
  }
  const scopedSlots = childrenArray.reduce(
    (res, child) => {
      const colOptions = fields[child.key] || {};
      res[child.key] = () => (
        <td className={classnames(colOptions.className)} onClick={colOptions.onClick}>
          {child}
        </td>
      );
      return res;
    },
    defaultColHandle
  )

  return (
    <div className={'ienso-table-body'}>
      <div className="dataTable-content">
        <CDataTable
          outlined
          clickableRows={isClickableRows}
          sorter={{external: true, resetable: false}}
          items={items || []}
          fields={coreUiFields}
          scopedSlots={{
            ...scopedSlots,
          }}
          {...props}
          onSorterValueChange={onSorterChange}
        />
      </div>
    </div>
  );
};

export default IensoTableBody;
