import {
  getClientByIdNext,
  getDevicesByClientIdNext,
  getSpacesListByClientIdNext,
  SET_USER_ROLES,
  SELECT_DEVICE,
  getRealmPackageListNext,
} from "./actionType";

const initialState = {
  clientData: null,
  isLoadingClientData: false,
  spacesList: [],
  isLoadingSpaceList: true,
  devicesList: [],
  isLoadingDevicesList: true,
  userRoles: null,
  selectedItem: null,
  realmPackageList: []
};

export const reducer = (state = initialState, {type, ...rest}) => {
  const {payload} = rest;
  switch (type) {
    case getSpacesListByClientIdNext.SUCCESS:
      return {...state, spacesList: payload, isLoadingSpaceList: false};
    case getSpacesListByClientIdNext.ERROR:
      return {...state, spacesList: null, isLoadingSpaceList: false};
    case getClientByIdNext.INIT:
      return {...state, isLoadingClientData: true};
    case getClientByIdNext.SUCCESS:
      return {...state, clientData: payload, isLoadingClientData: false};
    case getDevicesByClientIdNext.SUCCESS:
      return {...state, devicesList: payload, isLoadingDevicesList: false};
    case getDevicesByClientIdNext.ERROR:
      return {...state, devicesList: null, isLoadingDevicesList: false};
    case SET_USER_ROLES:
      let rolesObj = JSON.parse(JSON.stringify(rest?.userRoles));
      rolesObj["ui-components"] = {
        editor: true,
        admin: true,
        viewer: true,
      };
      return {...state, userRoles: rolesObj};
    case SELECT_DEVICE:
      return {...state, selectedItem: payload};

      case getRealmPackageListNext.SUCCESS:
        return {...state, realmPackageList: payload?.list }
    default:
      return state;
  }
};
