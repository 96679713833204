import {
  getCloudUsageNext,
  getUsersDevicesSpacesDataNext,
  getDeviceModelsNext, getFirmwareUsageNext,
  getLatestUpdatesNext,
  getUserRegionsNext,
  SELECT_MSG, getDeviceLogsInfoNext
} from "./actionType";

const initialState = {
  headerMainData: null,
  isLoadingHeaderMainData: false,
  latestUpdatesData: null,
  isLoadingLatestUpdates: true,
  cloudUsageData:null,
  isLoadingCloudUsageData:true,
  deviceModelsData:null,
  isLoadingDeviceModelsData:true,
  userRegionsData:null,
  isLoadingUserRegionsData:true,
  firmwareUsageData:null,
  isLoadingFirmwareUsageData:true,
  dashboardMainData:null,
  isLoadingDashboardMainData:true,
  selectedMsg:null,
  deviceLogsInfo:null,
  isLoadingDeviceLogsInfo:true
}

export const reducer = (state = initialState, {type, ...rest}) => {
  const {payload} = {...rest}

  switch (type) {
    case getUsersDevicesSpacesDataNext.SUCCESS:
      return {...state, dashboardMainData: rest?.payload, isLoadingDashboardMainData: false}
    case getUsersDevicesSpacesDataNext.ERROR:
      return {...state, dashboardMainData: null, isLoadingDashboardMainData: false}
    //======================================
    case getLatestUpdatesNext.SUCCESS:
      return {...state, latestUpdatesData: rest?.payload.slice(0,6), isLoadingLatestUpdates: false}
    case getLatestUpdatesNext.ERROR:
      return {...state, latestUpdatesData:null, isLoadingLatestUpdates: false}
    //======================================
    case getCloudUsageNext.SUCCESS:
      return {...state, cloudUsageData: rest?.payload, isLoadingCloudUsageData: false}
    case getCloudUsageNext.ERROR:
      return {...state, cloudUsageData:null, isLoadingCloudUsageData: false}
    //======================================
    case getDeviceModelsNext.SUCCESS:
      return {...state, deviceModelsData: rest?.payload, isLoadingDeviceModelsData: false}
    case getDeviceModelsNext.ERROR:
      return {...state, deviceModelsData:null, isLoadingDeviceModelsData: false}
    //======================================
    case getUserRegionsNext.SUCCESS:
      return {...state, userRegionsData: rest?.payload, isLoadingUserRegionsData: false}
    case getUserRegionsNext.ERROR:
      return {...state, userRegionsData:null, isLoadingUserRegionsData: false}
    //======================================
    case getFirmwareUsageNext.SUCCESS:
      return {...state, firmwareUsageData: rest?.payload?.firmwareList, isLoadingFirmwareUsageData: false}
    case getFirmwareUsageNext.ERROR:
      return {...state, firmwareUsageData:null, isLoadingFirmwareUsageData: false}
    case getDeviceLogsInfoNext.SUCCESS:
      return {...state,deviceLogsInfo:payload,isLoadingDeviceLogsInfo: false }
    case getDeviceLogsInfoNext.ERROR:
      return {...state,isLoadingDeviceLogsInfo:false }
    case SELECT_MSG:
      return {...state, selectedMsg: payload}
    default:
      return state
  }
}
