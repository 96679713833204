import {NextApi} from "../Next/next";

export const GET_DEVICE_TYPES='GET_DEVICE_TYPES'
export const GET_DEVICE_TYPES_IDS='GET_DEVICE_TYPES_IDS'

export const getDeviceTypesNext = new NextApi("device-manager/operator/device-type/device-types", GET_DEVICE_TYPES, {
  method: "GET", json: false,
});

//TODO: change route
export const getDeviceTypesIdsNext = new NextApi("device-manager/operator/device-type/device-types", GET_DEVICE_TYPES, {
  method: "GET", json: false,
});
