import React from 'react';
import './IensoButton.scss'
import './IensoButtonMain.scss'
import './IensoButtonSecondary.scss'
import './IensoButtonModalMain.scss'
import './IensoButtonModalSecondary.scss'
import {CButton} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import classnames from "classnames";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {buttonColorSelector} from "../../../../redux/service/realm-info/selectors";

/**
 * styleType = 'main', 'secondary', 'modal-main', 'modal-secondary'
 * size = 'lg', 'sm'
 */
export const IensoButton =  React.memo(({children, icon, className, styleType, onClick, disabled, iconSrc, size, ...props}) => {
  const buttonColor = useSelector(buttonColorSelector)
  return (
    <div className={classnames('ienso-button')}>
      <CButton {...props}
               style={{backgroundColor: (styleType === 'main' || styleType === 'modal-main') && buttonColor}}
               onClick={onClick}
               className={classnames(className,size, 'text-body-1', (children && !icon && styleType), (!children && `${styleType}-icon-only`), (children && icon && `${styleType}-with-icon`))}
               disabled={disabled}>
        {iconSrc && <CIcon src={iconSrc}/>}
        {icon && icon}
        {children}
      </CButton>
    </div>
  )
})


IensoButton.defaultProps = {
  styleType: 'main',
  icon: null,
  className: null,
  disabled: false,
  children:null,
  onClick:()=>undefined,
  size:'sm'
}


IensoButton.propTypes = {
  styleType: PropTypes.string,
  icon: PropTypes.element,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  onClick:PropTypes.func
}
