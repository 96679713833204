import {SET_USER_ROLES, userDataNext, userRolesNext} from "./actionTypes";
import { formatUserRoles } from "src/service/utils/utilFunctions";


const initialState = {
  isLoading: true,
  userRoles: null,
  userInfo: null,
  userRealRoles: null
}

export const reducer = (state = initialState, {type, ...rest}) => {
  switch (type) {
    case userRolesNext.SUCCESS:
      return {...state, userRealRoles: formatUserRoles(rest?.payload?.roles)}
    case userDataNext.SUCCESS:
      return {...state, userInfo: rest?.payload, isLoading: false}
    case userDataNext.ERROR:
      return {...state, userInfo: null, isLoading: false}
    case SET_USER_ROLES:
      let rolesObj = JSON.parse(JSON.stringify(rest?.userRoles))
      rolesObj['ui-components'] = {
        "editor": true,
        "admin": true,
        "viewer": true
      }
      //TODO: to delete!!

      rolesObj['device-database'] = {
        "editor": true,
        "admin": true,
        "viewer": true
      }

      rolesObj['analytics-management'] = {
        "editor": true,
        "admin": true,
        "viewer": true
      }

      rolesObj['campaign-management'] = {
        "editor": true,
        "admin": true,
        "viewer": true
      }
      rolesObj["integrations"] = {
        editor: true,
        admin: true,
        viewer: true,
      };
      rolesObj["customer-insights"] = {
        editor: true,
        admin: true,
        viewer: true,
      };
      return {...state, userRoles: rolesObj}
    default:
      return state
  }
}
