import React from 'react';
import './IensoTableHeader.scss'
import IensoRowPerPage from "../additional-components/rows-per-page/IensoRowPerPage";
import {IensoTableFilters} from "../additional-components/filters/filters/IensoTableFilters";
import {ExportCvs} from "../additional-components/export-cvs/ExportCVS";
import classnames from "classnames";

export const IensoTableHeader = ({
                                  customFilter,  
                                  title,
                                   showTitle,
                                   showPerPage,
                                   filterOptions,
                                   onFilterChange,
                                   headerFilters,
                                   exportCsv,
                                   exportCsvHandler,
                                   resetFilters,
                                   changedFilterFlag,
                                   showHeaderBottomRow,
                                   showFilterBy,
                                 }) => {

  return (
    <div
      className={classnames(
        "ienso-table-header",
        !showHeaderBottomRow && "hide-header-bottom-row"
      )}
    >
      <div className={"ienso-table-header__filters-row"}>
        {(filterOptions || customFilter) && (
          <IensoTableFilters
            customFilter={customFilter}
            resetFilters={resetFilters}
            options={filterOptions || []}
            onFilterChange={onFilterChange}
            values={headerFilters}
            showFilterBy={showFilterBy}
            changedFilterFlag={changedFilterFlag}
          />
        )}
      </div>
      {showHeaderBottomRow && (
        <div className={"ienso-table-header__bottom-row"}>
          {showTitle && <span className={"title"}>{title}</span>}
          {showPerPage && (
            <IensoRowPerPage
              onFilterChange={onFilterChange}
              value={headerFilters?.limit}
              className={"float-right select-items-per-page"}
            />
          )}
          {exportCsv && <ExportCvs exportCsvHandler={exportCsvHandler} />}
        </div>
      )}
    </div>
  );
};

IensoTableHeader.defaultProps = {
  title: 'Title Is Here',
  showPerPage: true,
  showTitle: true,
}
