export const localStorageConfig = {
   AUTH_TOKEN: 'AUTH_TOKEN_OPERATOR',
   TOKEN_EXPIRES_IN: 'TOKEN_EXPIRES_IN_OPERATOR',
   REFRESH_TOKEN_EXPIRES_IN: 'REFRESH_TOKEN_EXPIRES_IN_OPERATOR',
   REFRESH_TOKEN: 'REFRESH_TOKEN_OPERATOR',
   SELECTED_LANGUAGE: "SELECTED_LANGUAGE",
   REALM_NAME: 'REALM_NAME_OPERATOR',
   DARK_MODE: 'DARK_MODE',
}

