import { NextApi } from 'src/redux/service/Next/next';

export const DEVICE_MANAGEMENT_EVENTS = 'DEVICE_MANAGEMENT_EVENTS';
export const MODAL_DEVICE_MANAGEMENT_EVENTS = 'MODAL_DEVICE_MANAGEMENT_EVENTS';

export const getDeviceManagementEventsNext = new NextApi(`analytics/device-management-events`, DEVICE_MANAGEMENT_EVENTS, {
  method: 'GET', json: false,
});

export const getModalDeviceManagementEventsNext = new NextApi(`analytics/device-management-events`, MODAL_DEVICE_MANAGEMENT_EVENTS, {
  method: 'GET', json: false,
});
