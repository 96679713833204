const iensoIcons = {
  checkboxChecked: require('./checkboxChecked.svg').default,
  //============================main-icons==========================
  arrowBack: require('./arrowBack.svg').default,
  arrow: require('./arrow.svg').default,
  dropdownToggle: require('./dropdownToggle.svg').default,
  uploadImg: require('./uploadImg.svg').default,
  plus: require('./plus.svg').default,
  sidebarToggle: require('./sidebarToggle.svg').default,
  exportCSV:require('./exportCSV.svg').default,
  warningIcon:require('./warning.svg').default,
  alertIcon:require('./alert.svg').default,
  selectedGreenOption: require('./selctedGreenOption.svg').default,
  infoIcon:require('./info.svg').default,
  infoLightIcon:require('./infoLight.svg').default,
  defaultImg:require('./defaultImg.jpg').default,
  smartZone:require('./smartZone.svg').default,
  yellowWarning:require('./yellowWarning.svg').default,
  deleteClient:require('./deleteClient.svg').default,
  bigSelectedGreenV: require('./bigSelectedGreenV.svg').default,

}
export default iensoIcons;
