import React from 'react';
import './IensoHeaderRowBtnSearch.scss'
import {IensoButton, Search} from "src/iensoink/ui-components";
import classnames from "classnames";


export const IensoHeaderRowBtnSearch = React.memo(({
                                                     updateDataWithNewQuery,
                                                     useButtonWithSearch,
                                                     iconBtn,
                                                     btnText,
                                                     buttonClickHandler,
                                                     customSearchPlaceHolder,
                                                     updateQuery,
                                                     query
                                                   }) => {
  return (
    <div className={"ienso-header-row-btn-search"}>
      <div className={"ienso-header-row-btn-search__wrapper"}>
        <Search
          customSearchPlaceHolder={customSearchPlaceHolder}
          className={classnames(
            "ienso-header-row-btn-search__wrapper__search",
            !useButtonWithSearch &&
              "ienso-header-row-btn-search__wrapper__search-without-margin"
          )}
          query={query}
          updateQuery={updateQuery}
        />
        {useButtonWithSearch && (
          <IensoButton
            className={"ienso-header-row-btn-search__wrapper__add-btn"}
            size={"lg"}
            icon={iconBtn}
            onClick={buttonClickHandler}
          >
            {btnText}
          </IensoButton>
        )}
      </div>
    </div>
  );
})
