import { getDeviceEventsNext, getModalDeviceEventsNext } from './actionType';

const initialState = {
  deviceEvents: null,
  modalDeviceEvents: null
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case getDeviceEventsNext.INIT:
      return { ...state, deviceEvents: null }
    case getDeviceEventsNext.SUCCESS:
      return { ...state, deviceEvents: payload }
    case getModalDeviceEventsNext.INIT:
      return { ...state, modalDeviceEvents: null }
    case getModalDeviceEventsNext.SUCCESS:
      return { ...state, modalDeviceEvents: payload }
    default:
      return state
  }
}
