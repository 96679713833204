import React from 'react';
import './IensoTextField.scss'
import {IensoErrorMsg} from "../error-msg/IensoErrorMsg";

export const IensoTextField = React.memo(({
                                               label,
                                               id,
                                               value,
                                               onChange,
                                               invalid,
                                               setErrors,
                                               name,
                                               errors,
                                               maxCharsLength,
                                               placeholder
                                             }) => {
  return (
    <div className={'ienso-text-field'}>
      <div>
        <span className={'text-body-1 label'}>{label}</span>
        <textarea id={id} name={id} className={'custom-text-area'} value={value} placeholder={placeholder}
                  onChange={(e) => {
                    const strSize = e.target.value.length
                    if (strSize <= maxCharsLength) {
                      onChange(e)
                    }

                    if (maxCharsLength && strSize - 1 === maxCharsLength) {
                      setErrors({
                        ...errors,
                        [name]: `Max size ${maxCharsLength}`
                      })
                    }
                  }}
        />
      </div>
      {invalid && <IensoErrorMsg msg={errors[id]}/>}
    </div>
  );
})
IensoTextField.defaultProps = {
  label: '',
  id: '',
  maxCharsLength: 100,
  value: null,
  onChange: () => undefined
}
