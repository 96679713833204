import React from 'react';
import {CCard} from '@coreui/react'
import './IensoContentCard.scss'
import CIcon from '@coreui/icons-react'
import iensoIcons from "src/iensoink/ui-components/icons/main";
import classnames from "classnames";
import {IensoHeaderRowBtnSearch} from "src/iensoink/ui-components";
import PropTypes from "prop-types";

export const IensoContentCard = React.memo(({
                                              children,
                                              title,
                                              arrow,
                                              arrowBackHandler,
                                              className,
                                              searchWithBtn,
                                              updateDataWithNewQuery,
                                              buttonClickHandler,
                                              iconBtn,
                                              btnText,
                                              useButtonWithSearch,
                                              customSearchPlaceHolder,
                                              query,
                                              updateQuery
                                            }) => {
  return (
    <div className={classnames('ienso-content-card', className)}>
      <CCard className={'ienso-content-card__wrapper'}>
        <div className={classnames('ienso-content-card__wrapper__header', !title && !arrow && 'no-header')}>
          {arrow && <CIcon className={'arrow-icon'} src={iensoIcons.arrowBack} onClick={arrowBackHandler}/>}
          {title && <span className={'title text-subheading-1'}>{title}</span>}
          {searchWithBtn && <IensoHeaderRowBtnSearch updateDataWithNewQuery={updateDataWithNewQuery}
                                                     buttonClickHandler={buttonClickHandler}
                                                     iconBtn={iconBtn}
                                                     btnText={btnText}
                                                     useButtonWithSearch={useButtonWithSearch}
                                                     customSearchPlaceHolder={customSearchPlaceHolder}
                                                     query={query}
                                                     updateQuery={updateQuery}
          />}
        </div>
        <div className={'ienso-content-card__wrapper__content'}>
          {children}
        </div>
      </CCard>
    </div>
  );
})

IensoContentCard.defaultProps = {
  title: '',
  arrow: false,
  arrowBackHandler: () => undefined,
  className: '',
  searchWithBtn: false,
  updateDataWithNewQuery: () => undefined,
  buttonClickHandler: () => undefined,
  iconBtn: null,
  btnText: 'IensoContentCard.ButtonText',
  useButtonWithSearch: true,
  customSearchPlaceHolder: 'IensoContentCard.SearchPlaceholderText',
  query: {},
  updateQuery: () => undefined
}
IensoContentCard.propTypes = {
  title: PropTypes.string,
  arrow: PropTypes.bool,
  arrowBackHandler: PropTypes.func,
  className: PropTypes.string,
  searchWithBtn: PropTypes.bool,
  updateDataWithNewQuery: PropTypes.func,
  buttonClickHandler: PropTypes.func,
  iconBtn: PropTypes.node,
  btnText: PropTypes.string,
  customSearchPlaceHolder: PropTypes.string
}
