import {
  createPackageNext,
  getPackageByIdNext,
  RESET_DATA,
  SELECT_PACKAGE_ITEM,
  updatePackageNext,
  CREATE_PACKAGE_VALUES,
} from "./actionType";

const initialState = {
  data: null,
  isLoading: true,
  selectedItem: null,
  createValues: {},
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CREATE_PACKAGE_VALUES:
      return { ...state, createValues: payload };
    case getPackageByIdNext.SUCCESS:
      return { ...state, data: payload, isLoading: false };
    case getPackageByIdNext.ERROR:
      return { ...state, data: null, isLoading: true };
    case createPackageNext.SUCCESS:
      return { ...state, id: payload?.packageId };
    case updatePackageNext.SUCCESS:
      return { ...state };
    case updatePackageNext.ERROR:
      return { ...state };
    case RESET_DATA:
      return {
        ...state,
        data: null,
        isLoading: true,
        selectedItem: null,
        id: null,
        createValues: {},
      };
    case SELECT_PACKAGE_ITEM:
      return { ...state, selectedItem: payload };
    default:
      return state;
  }
};
