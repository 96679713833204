import {NextApi, NextAuth} from "../Next/next";
import {getCurrentUrl} from "../../../service/utils/utilFunctions";

export const GET_USER_DATA = "GET_USER_DATA"
export const SET_USER_ROLES='SET_USER_ROLES'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const GET_USER_ROLES = 'GET_USER_ROLES'

export const userRolesNext = new NextApi('whitelabel/operators/{userId}', GET_USER_ROLES, {method: 'GET'});
export const restUserPasswordNext = new NextApi('whitelabel/operators/reset-password/{userId}', RESET_PASSWORD, {method: 'PUT'});
export const userDataNext = new NextAuth(`{realmName}/protocol/openid-connect/userinfo?redirect_uri=${getCurrentUrl()}`, GET_USER_DATA,{method: 'GET'});