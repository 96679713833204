import React from 'react';
import './IensoSwitch.scss'
import {CSwitch} from "@coreui/react";
import classnames from "classnames";
import PropTypes from "prop-types";

export const IensoSwitch =React.memo( ({checked, handleClick, className, name,disabled}) => {
  return (
    <div className={classnames('ienso-switch', className, checked && 'checked',disabled && 'disabled')}>
      <CSwitch name={name}
               variant={'3d'}
               color={'primary'}
               value={checked}
               checked={checked}
               disabled={disabled}
               onChange={handleClick}
      />
    </div>
  );
})

IensoSwitch.defaultProps = {
  checked: false,
  handleClick: () => undefined,
  className: '',
  name: '',
  disabled:false
}
IensoSwitch.propTypes = {
  checked: PropTypes.bool,
  handleClick: PropTypes.func,
  className: PropTypes.string,
  name: PropTypes.string,
  disabled:PropTypes.bool
}
