import {combineReducers} from "redux";
import {reducer as auth} from "./service/auth/reducer";
import {reducer as app} from "./service/app/reducer";
import {reducer as firmware} from "./service/firmware/reducer";
import {reducer as realmInfo} from "./service/realm-info/reducer";
import {i18n} from './service/i18n/reducer'
import {reducer as whiteLabelSettings} from '../views/white-label-settings/redux/reducer'
import {reducer as messagingCenter} from '../views/messaging-center/redux/reducer'
import {reducer as userInfo} from './service/user-info/reducer'
import {reducer as operatorInfo} from '../views/operators-management/operator-info-page/redux/reducer'
import {reducer as operators} from "../views/operators-management/operaors-main-page/redux/reducer"
import {reducer as operatorsList} from './service/operators-list/reducer'
import {reducer as clients} from '../views/clients-management/clients-main-page/redux/reducer'
import {reducer as software} from '../views/software-management/redux/reducer';
import {reducer as deviceManagement} from '../views/device-management/redux/reducer';
import {reducer as dashboard} from '../views/dashboard/redux/reducer';
import {reducer as clientInfo} from '../views/clients-management/client-info-page/redux/reducer';
import {reducer as deviceLogs} from '../views/device-logs/redux/reducer'
import {reducer as deviceSettingsPage} from '../views/device-settings-page/redux/reducer'
import {reducer as spaceInfo} from './service/space-info/reducer'
import {reducer as auditLog} from '../views/audit-log/redux/reducer';
import {reducer as packageManagement} from 'src/views/package-management/main-page/redux/packages/reducer'
import {reducer as createPackage} from '../views/package-management/info-page/redux/reducer'
import {reducer as deviceFeatures} from './service/device-features/reducer'
import {reducer as deviceType} from './service/deviceType/reducer'
import {reducer as securityInsights} from '../views/security-insights/redux/reducer';
import {reducer as packageComparison} from 'src/views/package-management/info-page/package-comparison/redux/reducer';
import {reducer as campaignList} from '../views/campaign-management/redux/reducer';
import {reducer as clientSpaceInfo} from '../views/clients-management/client-info-page/components/client-space-list/components/space-list/redux/reducer';
import {reducer as deviceEventChart} from 'src/views/analytics-charts/DeviceEventChart/redux/reducer';
import {reducer as deviceFirmwareChart} from 'src/views/analytics-charts/DeviceFirmwareChart/redux/reducer';
import {reducer as deviceStatusChart} from 'src/views/analytics-charts/DeviceStatusChart/redux/reducer';
import {reducer as spaceChart} from 'src/views/analytics-charts/SpaceChart/redux/reducer';
import {reducer as subscriptionChart} from 'src/views/analytics-charts/SubscriptionChart/redux/reducer';
import {reducer as notificationChart} from 'src/views/analytics-charts/NotificationChart/redux/reducer';
import {reducer as deviceManagementChart} from 'src/views/analytics-charts/DeviceManagementChart/redux/reducer';
import {reducer as adminUserChart} from 'src/views/analytics-charts/AdminUserChart/redux/reducer';
import {reducer as clientDataChart} from 'src/views/analytics-charts/ClientDataChart/redux/reducer';

export const rootReducer = combineReducers({
  app,
  auth,
  realmInfo,
  i18n,
  whiteLabelSettings,
  messagingCenter,
  userInfo,
  operators,
  operatorInfo,
  operatorsList,
  clients,
  software,
  deviceManagement,
  firmware,
  dashboard,
  clientInfo,
  deviceLogs,
  deviceSettingsPage,
  spaceInfo,
  auditLog,
  packageManagement,
  createPackage,
  deviceFeatures,
  deviceType,
  securityInsights,
  packageComparison,
  campaignList,
  clientSpaceInfo,
  deviceEventChart,
  deviceFirmwareChart,
  deviceStatusChart,
  spaceChart,
  subscriptionChart,
  notificationChart,
  deviceManagementChart,
  adminUserChart,
  clientDataChart
})
