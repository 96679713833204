import {
  deletePackageNext,
  getPackageListNext,
  SELECT_PACKAGE,
  SELECT_PACKAGE_ITEM
} from "./actionType";

const initialState = {
  isLoading: true,
  list: null,
  totalItems: null,
  selectedId: null,
  selectedItem:null
}

export const reducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case getPackageListNext.SUCCESS:
      return {...state, list: payload?.list, isLoading: false, totalItems: payload?.totalItems}
    case getPackageListNext.ERROR:
      return {...state, isLoading: false, list: null, totalItems: null}
    case deletePackageNext.INIT:
      return {...state, isLoading: true}
    case deletePackageNext.SUCCESS:
      return {...state, isLoading: false}
    case SELECT_PACKAGE:
      return {...state, selectedId: payload}
    case SELECT_PACKAGE_ITEM:
      return {...state, selectedItem: payload}
    default:
      return state
  }
}
