import {
  getUrlToUploadFileToS3Next,
  getFirmwareListNext,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_ERROR,
  TURN_OFF_CLEAR_FORM_FIELDS
} from './actionType';

const initialState = {
  isLoading: true,
  isLoadingFirmwareList:true,
  url: '',
  firmwareList: [],
  clearFormFields: false,
  uuid: ''
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case getUrlToUploadFileToS3Next.INIT:
      return { ...state, isLoading: true }
    case getUrlToUploadFileToS3Next.SUCCESS:
      return { ...state, url: payload.url, uuid: payload.uuid, isLoading: false }
    case getUrlToUploadFileToS3Next.ERROR:
      return { ...state, url: '', uuid: '', isLoading: false }
//============
    case getFirmwareListNext.SUCCESS:
      return { ...state, firmwareList: payload.firmwareList, isLoadingFirmwareList: false }
    case getFirmwareListNext.ERROR:
      return { ...state, isLoadingFirmwareList: false }
//============
    case UPLOAD_FILE_SUCCESS:
      return {...state, url: '', uuid: '', clearFormFields: true}
    case UPLOAD_FILE_ERROR:
      return state;
    case TURN_OFF_CLEAR_FORM_FIELDS:
      return {...state, clearFormFields: false}
    default:
      return state
  }
}
