import React, {useEffect} from 'react';
import {isLoadingFirmwareListSelector} from "../redux/service/firmware/selectors";
import {useDispatch, useSelector} from "react-redux";
import {isLoadingDeviceTypesListSelectorSelector} from "../redux/service/deviceType/selectors";
import {IensoLoader} from "src/iensoink/ui-components";
import {getDeviceTypesList} from "../redux/service/deviceType/actions";
import {getFirmwareList} from "../redux/service/firmware/actions";

const CommonAppDataLoadingWrapper = ({children}) => {
  const dispatch = useDispatch()
  const isLoading = [
    useSelector(isLoadingFirmwareListSelector),
    useSelector(isLoadingDeviceTypesListSelectorSelector)
  ].some(bool => bool)


  useEffect(() => {
    dispatch(getDeviceTypesList())
    dispatch(getFirmwareList())
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isLoading ? <IensoLoader/> : children
};

export default CommonAppDataLoadingWrapper;
