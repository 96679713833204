import {getFirmwareListNext,getFilteredFirmwareListNext} from "./actionTypes";
import {createHttpAction} from "../Next/http-fetch";

export const getFirmwareList = (onSuccess, onError) => createHttpAction(getFirmwareListNext, null, {
  onSuccess,
  onError
}).action;

export const getFilteredFirmwareList = (onError) => createHttpAction(getFilteredFirmwareListNext, null, {
  onError
}).action;
