import { NextApi } from "src/redux/service/Next/next";

export const CAMPAIGN_GET = "CAMPAIGN_GET";
export const CAMPAIGN_CREATE = "CAMPAIGN_CREATE";

const CAMPAIGN_API_URL_PATH = "campaign-manager";

export const getCampaignListNext = new NextApi(
  CAMPAIGN_API_URL_PATH,
  CAMPAIGN_GET,
  {
    method: "GET",
    json: false,
  }
);

export const createCampaignNext = new NextApi(
  CAMPAIGN_API_URL_PATH,
  CAMPAIGN_CREATE,
  {
    method: "POST",
    json: true,
  }
);
