import React from "react";
import Filters from "./Filters";
import "./IensoTableFilters.scss";
import classnames from "classnames";
import { IensoButton } from "../../../../../index";
import { BsArrowClockwise } from "react-icons/all";
import Translate from "src/redux/service/i18n";

export const IensoTableFiltersTypes = {
  select: "SELECT",
  date: "DATE",
  selectCategories: "SELECT_CATEGORIES",
};

export const IensoTableFilters = React.memo(
  ({
    values,
    customFilter,
    options,
    onFilterChange,
    text,
    resetFilters,
    showFilterBy,
    changedFilterFlag,
  }) => {
    return (
      <>
        <div className="ienso-filter">
          <div
            className={classnames(
              "ienso-table-filters",
              !showFilterBy && "hidden-filter-title"
            )}
          >
            <div className={classnames("filters-row pt-2 mb-1")}>
              {showFilterBy && (
                <span className={"filters-row__title text-body-1"}><Translate textKey={text}/></span>
              )}

              {customFilter && customFilter}
              <Filters
                values={values}
                options={options}
                onFilterChange={onFilterChange}
              />
              <IensoButton
                styleType={"secondary"}
                className={classnames("reset-filters-btn text-body-2")}
                icon={<BsArrowClockwise />}
                disabled={!changedFilterFlag}
                onClick={resetFilters}
              >
                <Translate textKey='IensoTableFilters.ClearFilters'/>
              </IensoButton>
            </div>
          </div>
        </div>
      </>
    );
  }
);

IensoTableFilters.defaultProps = {
  values: {},
  onFilterChange: () => undefined,
  options: {},
  text: "Table.Filter.FilterBy",
};
