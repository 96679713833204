import {NextAuth} from "../Next/next";

export const GET_TOKEN = "GET_TOKEN"
export const VALIDATE_TOKEN = "VALIDATE_TOKEN"
export const REFRESH_TOKEN = 'REFRESH_TOKEN'

//===== CHANGE INFO ACTIONS ===========
export const getTokenNext = new NextAuth('{realmName}/protocol/openid-connect/token', GET_TOKEN, {
  method: 'POST',
  json: false,
  headers: {'Content-Type': 'application/x-www-form-urlencoded'}
});
export const refreshTokenNext = new NextAuth('{realmName}/protocol/openid-connect/token', REFRESH_TOKEN, {
  method: 'POST',
  json: false,
  headers: {'Content-Type': 'application/x-www-form-urlencoded'}
});
export const validateTokenNext = new NextAuth('{realmName}/protocol/openid-connect/token', VALIDATE_TOKEN, {method: 'GET'});
