import React from 'react';
import {CModal, CModalHeader, CModalBody, CModalFooter, CButton} from "@coreui/react";
import './IensoModalWindow.scss'
import PropTypes from "prop-types";
import {IensoButton} from "../../index";
import {BsXCircle} from "react-icons/all";

export const IensoModalWindow = React.memo(({
                                   children, isOpen, toggle, title,
                                   onSaveLabel, onCloseLabel, onCustomLabel,
                                   onSave, onClose, onCustom, size,
                                   showButtons, buttonsSize,onSaveDisabled, modalSize,
                                 }) => {
  return (
    <div className={'ienso-modal-window'}>
      <CModal
        show={isOpen}
        centered
        onClose={toggle}
        className={modalSize ? '' : size}
        size={modalSize}
      >
        <div className={'container'}>
          <CModalHeader className={'container__header'}>
            <span className={'title text-subheading-1'}>{title}</span>
            <CButton className={'close-btn'} onClick={toggle}><BsXCircle/></CButton>
          </CModalHeader>

          <CModalBody className={'container__body'}>
            {children}
          </CModalBody>
          {showButtons && <CModalFooter className={'container__footer'}>
            <div className={'btn-wrapper'}>
              {onCustomLabel &&
              <IensoButton className={'btn-wrapper__btn-left'}
                           onClick={onCustom} size={buttonsSize}
                           styleType={'modal-secondary'}>{onCustomLabel}</IensoButton>}

              <IensoButton className={'btn-wrapper__btn-left'}
                           onClick={onClose} size={buttonsSize}
                           styleType={'modal-secondary'}>{onCloseLabel}</IensoButton>

              <IensoButton className={'btn-wrapper__btn-right'} size={buttonsSize} disabled={onSaveDisabled}
                           styleType={'modal-main'} onClick={onSave}>{onSaveLabel}</IensoButton>
            </div>
          </CModalFooter>}
        </div>
      </CModal>
    </div>
  );
})

IensoModalWindow.propTypes = {
  children: PropTypes.node,
  size: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  title: PropTypes.string,
  onSaveLabel: PropTypes.string,
  onCloseLabel: PropTypes.string,
  onCustomLabel: PropTypes.string,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  onCustom: PropTypes.func,
  showButtons: PropTypes.bool
}

IensoModalWindow.defaultProps = {
  children: null,
  size: 'md',
  buttonsSize:'sm',
  isOpen: false,
  toggle: () => undefined,
  title: 'Title is here',
  onSaveLabel: 'Save',
  onCloseLabel: 'Cancel',
  onCustomLabel: '',
  onSave: () => undefined,
  onClose: () => undefined,
  onCustom: () => undefined,
  showButtons: true
}
