import {
  getPackageByIdNext,
} from "./actionType";

const initialState = {
  packages: {},
};

export const reducer = (state = initialState, {type, ...rest}) => {
  const {payload} = rest;
  switch (type) {
    case getPackageByIdNext.SUCCESS:
      const packageId = payload.packageId;
      return {...state, packages: {...state.packages, [packageId]: payload}};
    case getPackageByIdNext.ERROR:
      return {...state};
    default:
      return state;
  }
};
