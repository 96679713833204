import { getClientDataEventsNext, getModalClientDataEventsNext } from './actionType';

const initialState = {
  clientDataEvents: null,
  modalClientDataEvents: null,
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case getClientDataEventsNext.INIT:
      return { ...state, clientDataEvents: null }
    case getClientDataEventsNext.SUCCESS:
      return { ...state, clientDataEvents: payload }
    case getModalClientDataEventsNext.INIT:
      return { ...state, modalClientDataEvents: null }
    case getModalClientDataEventsNext.SUCCESS:
      return { ...state, modalClientDataEvents: payload }
    default:
      return state
  }
}
