import { NextApi } from '../../../../redux/service/Next/next';

export const OPERATORS_SET_SELECTED_TO_NULL='OPERATORS_SET_SELECTED_TO_NULL'
export const OPERATORS_TOGGLE_MODAL_WINDOW = 'OPERATORS_TOGGLE_MODAL_WINDOW'
export const OPERATORS_GET_BY_ID = 'OPERATORS_GET_BY_ID'
export const OPERATORS_PUT = 'OPERATORS_PUT'
export const OPERATORS_POST = 'OPERATORS_POST'
export const SET_OPERATOR_ROLE = 'SET_OPERATOR_ROLE';
export const SET_OPERATOR_FIELD = 'SET_OPERATOR_FIELD';
export const SET_OPERATOR_INFO = 'SET_OPERATOR_INFO';

const OPERATORS_API_URL_PATH = '/whitelabel/operators'

export const getOperatorByIdNext = new NextApi(`${OPERATORS_API_URL_PATH}/{operatorId}`, OPERATORS_GET_BY_ID, {
  method: 'GET', json: false,
});

export const putOperatorNext = new NextApi(`${OPERATORS_API_URL_PATH}/{operatorId}`, OPERATORS_PUT, {
  method: 'PUT', json: true,
});

export const postOperatorNext = new NextApi(OPERATORS_API_URL_PATH, OPERATORS_POST, {
  method: 'POST', json: true,
});
