import React from 'react';
import {CTooltip} from '@coreui/react'
import './IensoTooltip.scss'

export const IensoTooltip =React.memo( ({children, text, id, placement}) => {
  return (
    <div className={'ienso-tooltip'}>
      <CTooltip
        content={text}
        placement={placement}
      >
        <div className='tooltip-text'>{children}</div>
      </CTooltip>
    </div>
  );
})
IensoTooltip.defaultProps = {
  children: null,
  text: 'Tooltip text',
  id: 'tooltip',
  placement: 'top'
}
