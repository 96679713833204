import React from 'react';
import {CButton} from "@coreui/react";
import {useSelector} from "react-redux";
import {brandColorSelector} from "../../../../redux/service/realm-info/selectors";
import './IensoEmptyButton.scss'
import classnames from "classnames";

export const IensoEmptyButton =  React.memo(({children, disabled, className, onClick, buttonClassName}) => {
  const brandColor = useSelector(brandColorSelector)

  return (
    <div className={classnames('ienso-empty-button', disabled && 'disabled', className && className)}>
      <CButton className={buttonClassName} onClick={onClick} disabled={disabled} style={!disabled ? {color: brandColor} : {}}>{children}</CButton>
    </div>
  );
})

IensoEmptyButton.defaultProps = {
  text: 'text',
  disabled: false,
  className: null,
  onClick:()=>undefined
}

