import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import {useSelector} from "react-redux";
import {displayNameSelector} from "../redux/service/realm-info/selectors";

const HelmetWrapper = ({children}) => {
  const realmName = useSelector(displayNameSelector);
  const htmlTitle = realmName ? realmName.charAt(0).toUpperCase() + realmName.slice(1) : "No title"

  return (
    <HelmetProvider>
      <Helmet>
        <title>{htmlTitle}</title>
      </Helmet>
      {children}
    </HelmetProvider>
  );
};

export default HelmetWrapper;
