import React from 'react';
import './IensoRowPerPage.scss'
import {CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle} from "@coreui/react";
import classnames from "classnames";
import { translate } from 'src/redux/service/i18n';

const IensoRowPerPage = React.memo(({value, className, onFilterChange}) => {
  return (
    <div className={classnames("ienso-row-per-page", className)}>
      <span className={"ienso-row-per-page__title"}>
        {translate("IensoRowPerPage.RowsPerPage")}
      </span>
      <div className={"ienso-row-per-page__drop"}>
        <CDropdown inNav className={"dropdown-filter-by"}>
          <CDropdownToggle>
            {value}
            &nbsp;
          </CDropdownToggle>
          <CDropdownMenu className="dropdown-menu-items-per-page">
            {[10, 25, 50, 100]?.map((item) => {
              return (
                <CDropdownItem
                  id={item}
                  key={item}
                  disabled={value.toString() === item.toString()}
                  className={classnames(
                    value.toString() === item.toString() &&
                      "selected-item text-body-2"
                  )}
                  onClick={(e) => {
                    onFilterChange(e.target.id, "limit");
                  }}
                >
                  {item}
                </CDropdownItem>
              );
            })}
          </CDropdownMenu>
        </CDropdown>
      </div>
    </div>
  );
})

export default IensoRowPerPage;
