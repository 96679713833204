import { getAuditLogsNext, getOperatorListNext } from './actionType';

const initialState = {
  isLoading: true,
  dataList: null,
  totalItemsInList: 0,
  operatorList: null,
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case getAuditLogsNext.INIT:
      return { ...state, dataList: null, isLoading: true }
    case getAuditLogsNext.SUCCESS:
      return {
        ...state,
        dataList: (payload?.list || []).map(({ firstName, lastName, eventName, method, logDate }) => ({
          operator: `${firstName} ${lastName}`,
          action: eventName.split('.')[2],
          service: eventName.split('.')[1],
          method: method.toUpperCase(),
          createdOn: logDate,
        })),
        totalItemsInList: payload?.totalItems,
        isLoading: false
      }
    case getAuditLogsNext.ERROR:
      return { ...state, isLoading: false }
    case getOperatorListNext.INIT:
      return { ...state, operatorList: null, isLoading: true }
    case getOperatorListNext.SUCCESS:
      return {
        ...state,
        operatorList: (payload?.list || []).map(({ email }) => ({ id: email, label: email })),
        totalItemsInList: payload?.totalItems,
        isLoading: false
      }
    default:
      return state
  }
}
