import React from 'react';
import './IensoCheckboxBrand.scss'
import PropTypes from "prop-types";
import CIcon from '@coreui/icons-react';
import iensoIcons from "src/iensoink/ui-components/icons/main";
import classnames from "classnames";

export const IensoCheckboxBrand = React.memo(({id, checked, onChange, disabled, className}) => {
  return (
    <div className={classnames('ienso-checkbox-brand', className)}>
      <input
        id={id}
        checked={checked}
        disabled={disabled && !checked}
        onChange={(e) => onChange(e.target.checked)}
        type={'checkbox'}
        className='ienso-checkbox-brand__checkbox-input'
      />
      <CIcon src={iensoIcons.checkboxChecked} className='ienso-checkbox-brand__checkbox--filled'/>
      <div className='ienso-checkbox-brand__checkbox--empty'/>
    </div>
  );
})
IensoCheckboxBrand.defaultProps = {
  id: 'checkbox',
  checked: false,
  onChange: () => undefined,
  disabled: false
}
IensoCheckboxBrand.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
}
