import React from 'react';
import './ExportCvs.scss'
import {IensoButton} from "../../../../index";
import iensoIcons from "src/iensoink/ui-components/icons/main";

export const ExportCvs = React.memo(({exportCsvHandler}) => {
  return (
    <div className={'export-cvs'}>
      <IensoButton styleType={'secondary'} onClick={exportCsvHandler} iconSrc={iensoIcons.exportCSV}/>
    </div>
  );
})

