import { getAdminUserEventsNext, getModalAdminUserEventsNext } from './actionType';

const initialState = {
  adminUserEvents: null,
  modalAdminUserEvents: null,
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case getAdminUserEventsNext.INIT:
      return { ...state, adminUserEvents: null }
    case getAdminUserEventsNext.SUCCESS:
      return { ...state, adminUserEvents: payload }
    case getModalAdminUserEventsNext.INIT:
      return { ...state, modalAdminUserEvents: null }
    case getModalAdminUserEventsNext.SUCCESS:
      return { ...state, modalAdminUserEvents: payload }
    default:
      return state
  }
}
