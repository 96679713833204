import {deleteMessageByIdNext, getMessagesNext, SELECT_MSG} from "./actionType";

const initialState = {
  messages: null,
  totalItems: null,
  isLoadingMessages: true,
  selectedMsg: null
}

export const reducer = (state = initialState, {type, payload}) => {

  switch (type) {
    case getMessagesNext.INIT:
      return {...state, isLoadingMessages: true,messages: null, totalItems: null}
    case getMessagesNext.SUCCESS:
      return {...state, messages: payload.list, totalItems: payload?.totalItems, isLoadingMessages: false}
    case getMessagesNext.ERROR:
      return {...state, messages: null, totalItems: null, isLoadingMessages: false}
    case deleteMessageByIdNext.INIT:
      return {...state, isLoadingMessages: true}
    case deleteMessageByIdNext.SUCCESS:
      return {...state, isLoadingMessages: false}
    case SELECT_MSG:
      return {...state, selectedMsg: payload}
    default:
      return state
  }
}
