import React, {useState} from 'react';
import './FilterDatePicker.scss'
import "react-datepicker/dist/react-datepicker.css";
import {SingleDatePicker} from "react-dates";
import {BsCalendar3} from "react-icons/all";
import 'react-dates/lib/css/_datepicker.css';
import moment from "moment";

export const FilterDatePicker = React.memo(
  ({ selected, onFilterChange, filterId, label }) => {
    const [isFocused, setIsFocused] = useState(null);
    const handleFocusChange = ({ focused }) => setIsFocused(focused);

    return (
      <div className={"ienso-date-picker-wrapper"}>
        <div className="ienso-date-picker">
          <SingleDatePicker
            id={filterId}
            date={selected ? moment(selected) : null}
            onDateChange={(date) =>
              onFilterChange(moment(date).format("L"), filterId)
            }
            focused={isFocused}
            onFocusChange={handleFocusChange}
            placeholder={label}
            noBorder={true}
            isOutsideRange={() => false}
          ></SingleDatePicker>
          <BsCalendar3 className="ienso-date-picker-icon" />
        </div>
      </div>
    );
  }
);

