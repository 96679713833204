import {NextApi} from "../../../redux/service/Next/next";

export const GET_MESSAGES = 'GET_MESSAGES'
export const SEND_NEW_MSG = 'SEND_NEW_MSG';
export const DELETE_MSG_BY_ID = 'DELETE_MSG_BY_ID'
export const SELECT_MSG='SELECT_MSG'

const NEW_MSG_API_URL_PATH = '/whitelabel/messages/new';


export const getMessagesNext= new NextApi('whitelabel/messages', GET_MESSAGES, {
  method: 'GET', json: false,
});

export const sendMsgNext = new NextApi(NEW_MSG_API_URL_PATH, SEND_NEW_MSG, {
  method: 'POST', json: true,
});

export const deleteMessageByIdNext = new NextApi("whitelabel/messages/{messageId}", DELETE_MSG_BY_ID, {
  method: "DELETE", json: false,
});
