import {getOperatorsNext,} from './actionType';

const initialState = {
  datalist: null,
  totalItemsInList:null,
  isLoading: false,
}

export const reducer = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case getOperatorsNext.INIT:
      return { ...state, isLoading: true }
    case getOperatorsNext.SUCCESS:
      return {...state, datalist: rest?.payload?.list ,totalItemsInList:rest?.payload?.totalItems, isLoading: false}
    case getOperatorsNext.ERROR:
      return { ...state, isLoading: false, datalist: null }
    default:
      return state
  }
}
