export const updateListWithSelectedQuery = (updateDataWithNewQuery, headerFilters, history) => {
  const query = createQueryParamsObjForRequestWithFilters(headerFilters)
  updateDataWithNewQuery(query)
  const urlQueryStr = createQueryUrlString(query)
  urlQueryStr && history.push(urlQueryStr)
}

export const createQueryUrlString = (query) => Object.keys(query).reduce((res, key) => res += `${key}=${query[key]}&`, '?').slice(0, -1)

export const createQueryParamsObjForRequestWithFilters = (filters) => {
  return Object.keys(filters).reduce((res, key) => {
    filters[key] && (res[key] = filters[key])
    return res
  }, {})
}

export const createStateFiltersFromOptions = (options, headerFilters, currentQuery) => {
  let res = {...headerFilters}
  for (const optionsKey in options) {
    if (currentQuery && currentQuery[optionsKey]) res = {...res, [optionsKey]: currentQuery[optionsKey]}
  }
  return res
}

export const removePrevSortingValues = (filters) => {
  delete filters['order']
  delete filters['sortBy']

  return filters
}

export const setValueToLocalStorage = (key, val, isJson = false) => window.localStorage.setItem(key, isJson ? JSON.stringify(val) : val)

export const getValueFromLocalStorage = (key, isJson = false) => {
  try {
    if (isJson) {
      return JSON.parse(window.localStorage.getItem(key))
    } else {
      return window.localStorage.getItem(key)
    }
  } catch (e) {
    console.error('Failed to get from storage key:', key)
    console.error('Value', window.localStorage.getItem(key))
    console.error(e);
    return {};
  }
}
export const removeValueFromLocalStorage = (key) => localStorage.removeItem(key);

export const createUrlQueryForExportCsv = (headerFilters) => {
  const query = createQueryParamsObjForRequestWithFilters(headerFilters)
  return createQueryUrlString(query)
}

export const validate = (getValidationSchema) => {
  return (values) => {
    const validationSchema = getValidationSchema(values)
    try {
      validationSchema.validateSync(values, {abortEarly: false})
      return {}
    } catch (error) {
      return getErrorsFromValidationError(error)
    }
  }
}

export const getErrorsFromValidationError = (validationError) => {
  const FIRST_ERROR = 0
  return validationError.inner.reduce((errors, error) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR],
    }
  }, {})
}

export const getChartStep = (step) => {
  switch (step) {
    case '5m':
      return 'minute';
    case '1h':
      return 'hour';
    case '1d':
      return 'day';
    default:
      return 'day';
  }
}