import {
  APP_SELECT_LANGUAGE,
  APP_SET,
  APP_SET_NAV_ITEMS,
  APP_SET_TOASTER_MSG
} from "./actionTypes";

const initialState = {
  sidebarShow: 'responsive',
  selectedLang: "EN-CA",
  navigationItemsSidebar: [],
  messagesArr: [],
}

export const reducer = (state = initialState, {type, ...rest}) => {
  switch (type) {
    case APP_SET:
      return {...state, ...rest}
    case APP_SELECT_LANGUAGE:
      return {...state, ...rest}
    case APP_SET_NAV_ITEMS:
      return {...state, ...rest}
    case APP_SET_TOASTER_MSG:
      const arr = state.messagesArr
      arr.push(rest.msg)
      return {...state, messagesArr: [...arr]}
    default:
      return state
  }
}

