import React, { useEffect, useState } from "react";
import { CDropdown, CDropdownMenu, CDropdownToggle } from "@coreui/react";
import "./IensoMultiSelectionCategories.scss";
import { IensoMultiSelectionCategoriesItem } from "./IensoMultiSelectionCategoriesItem";
import { CButton } from "@coreui/react";
import { IensoEmptyButton } from "../../index";
import classnames from "classnames";
import { translate } from "src/redux/service/i18n";

const valueSelect = {
  SINGLE: 'SINGLE',
  MULTIPLE: 'MULTIPLE',
  NONE: 'NONE'
} 

export const IensoMultiSelectionCategories = ({
  setIsCleared,
  values,
  selected,
  items,
  placeholder,
  className,
  onChange = () => undefined,
  selectAll = true,
}) => {
  const [stateValues, setStateValues] = useState(values || selected || []);
  const [valuesSelectedCount, setValuesSelectedCount] = useState(valueSelect.NONE);

  useEffect(() => {
    switch (stateValues.length) {
      case 1:
        setValuesSelectedCount(valueSelect.SINGLE);
        break;
      case 0:
        setValuesSelectedCount(valueSelect.NONE);
        break;
      default:
        setValuesSelectedCount(valueSelect.MULTIPLE);
        break;
    }
  }, [stateValues.length]);

  let valuesHash = stateValues.reduce((res, item) => {
    res[item.value] = 1;
    return res;
  }, {});

  const getSubItems = (item, subItem = false) => {
    const returnItems = [];
    if (subItem) {
      returnItems.push(item);
    }
    if (item.items?.length) {
      item.items.forEach((item) =>
        returnItems.push.apply(returnItems, getSubItems(item, true))
      );
    }
    return returnItems;
  };

  const selectAllItem = () => {
    let arr = [];
    items.forEach((item) => {
      arr.push(item);
      if (item?.items) arr = [...arr, ...getSubItems(item)];
    });
    setStateValues(arr);
  };

  const findTopSelected = (items) => {
    let selectedItem = null;
    selectedItem = items.find((item) => {
      return valuesHash[item.value];
    });
    if (!selectedItem) {
      items
        .filter((item) => item.items?.length)
        .find((item) => {
          selectedItem = findTopSelected(item.items);
          return !!selectedItem;
        });
    }
    return selectedItem;
  };

  const getItems = (item, ignoreTop = false) => {
    const returnItems = [];
    if (!ignoreTop) {
      returnItems.push(item);
    }
    if (item.items?.length) {
      item.items.forEach((item) =>
        returnItems.push.apply(returnItems, getItems(item))
      );
    }
    return returnItems;
  };

  const fillFullSelectedParents = (values, items) => {
    items.forEach((item) => {
      const children = getItems(item, true);
      if (!children.length) {
        return;
      }
      if (item.items?.length) {
        values = fillFullSelectedParents(values, item.items);
      }
      const selectedChildren = children.filter(
        ({ value }) => valuesHash[value]
      );
      if (children.length !== selectedChildren.length) {
        delete valuesHash[item.value];
        values = values.filter(({ value }) => value !== item.value);
      } else {
        if (!valuesHash[item.value]) {
          valuesHash[item.value] = 1;
          values.push(item);
        }
      }
    });

    return values;
  };

  useEffect(() => {
    if (values !== undefined) {
      fillFullSelectedParents(values, items);
      setStateValues(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, items]);

  const markSelectedItems = (items, valuesHash) => {
    return items.map((item) => {
      if (valuesHash[item.value]) {
        item.selected = true;
      }
      if (item.items?.length) {
        item.items = markSelectedItems(item.items, valuesHash);
      }
      return item;
    });
  };
  const mapItems = (values) => {
    const sanitizedItems = JSON.parse(JSON.stringify(items));
    const valuesHash = values.reduce((res, { value }) => {
      res[value] = 1;
      return res;
    }, {});
    return markSelectedItems(sanitizedItems, valuesHash);
  };

  const toggleSelectedValue = (item) => {
    const affectedItems = item ? getItems(item) : [];

    if (!valuesHash[item.value]) {
      const changes = [];
      affectedItems
        .filter(({ value }) => !valuesHash[item.value])
        .forEach((item) => {
          if (!valuesHash[item.value]) {
            valuesHash[item.value] = 1;
            changes.push(item);
          }
        });
      const selectedValues = fillFullSelectedParents(
        [...stateValues, ...changes],
        items
      );
      setStateValues(selectedValues);
      onChange(selectedValues, mapItems(selectedValues));
    } else {
      const changesMap = affectedItems.reduce((res, item) => {
        delete valuesHash[item.value];
        res[item.value] = 1;
        return res;
      }, {});
      const selectedValues = fillFullSelectedParents(
        stateValues.filter(({ value }) => !changesMap[value]),
        items
      );
      setStateValues(selectedValues);
      onChange(selectedValues, mapItems(selectedValues));
    }
  };
  const topSelectedItem = findTopSelected(items || []);

  return (
    <div
      className={classnames(
        "ienso-multi-selection-categories",
        className,
        (stateValues.length) && "selected"
      )}
    >
      <CDropdown>
        <CDropdownToggle className={classnames(valuesSelectedCount !== valueSelect.NONE ? "value-selected text-body-2" : "text-body-1")}>
          {valuesSelectedCount === valueSelect.NONE && (placeholder || "-")}
          {valuesSelectedCount === valueSelect.SINGLE && `${topSelectedItem?.label} (1)`}
          {valuesSelectedCount === valueSelect.MULTIPLE &&
            `${placeholder || "-"} (${stateValues.length})`}
        </CDropdownToggle>
        <CDropdownMenu>
          {(items || []).map((item) => (
            <IensoMultiSelectionCategoriesItem
              key={item?.value}
              getSubItems={getSubItems}
              item={item}
              onToggle={toggleSelectedValue}
              valuesHash={valuesHash}
            />
          ))}
          <div className={"ienso-multi-selection-categories__buttons-wrapper"}>
            {!!selectAll && (
              <IensoEmptyButton
                className={"select-all-btn"}
                onClick={selectAllItem}
              >
                {translate("MultiSelect.SelectAll")}
              </IensoEmptyButton>
            )}
            <CButton
              disabled={valuesSelectedCount === valueSelect.NONE}
              className={"clear-btn text-tiny"}
              onClick={() => {
                setIsCleared(true);
                setStateValues([]);
              }}
            >
              {translate("MultiSelect.Clear")}
            </CButton>
          </div>
        </CDropdownMenu>
      </CDropdown>
    </div>
  );
};

IensoMultiSelectionCategories.defaultProps = {
  setIsCleared: () => undefined,
};
