import React from 'react';
import './IensoKeyValueText.scss'
import classnames from "classnames";

export const IensoKeyValueText = React.memo(({
                                               label,
                                               data,
                                               id,
                                               infoClassName,
                                               labelTextBody,
                                               dataTextBody
                                             }) => {
  return (
    <div className={classnames('info', infoClassName)} id={id}>
      <span className={classnames('label', labelTextBody)}>{label}: </span>
      <span className={classnames('data', dataTextBody)}>{data}</span>
    </div>
  );
})
IensoKeyValueText.defaultProps = {
  label: 'label',
  data: '-',
  id: 'id',
  infoClassName: null,
  labelTextBody: 'text-body-2',
  dataTextBody: 'text-body-3'
}
