import moment from "moment";
import {getTokenAction, refreshTokenAction} from "../../../redux/service/auth/actions";
import {getCurrentUrl, getValueFromLocalStorage} from "../utilFunctions";
import {localStorageConfig} from "../../../config";

export const isTokenExpired = () => {
  let accessToken = window.localStorage.getItem(localStorageConfig.AUTH_TOKEN)
  let tokenExpires = window.localStorage.getItem(localStorageConfig.TOKEN_EXPIRES_IN)
  try {
    if (!accessToken || accessToken === "") {
      throw new Error('No access token exists.')
    }
    if (!tokenExpires || tokenExpires === "") {
      throw new Error('No token expires date exists.')
    }
    if (moment(tokenExpires).isBefore(moment())) {
      throw new Error('Access token expired.')
    }
    return false
  } catch (error) {
    console.error(error.message)
    return true
  }
}

export const checkLoginStatus = (realmName) => {
  const code = new URL(window.location).searchParams.get("code")
  if (code) {
    getTokenAction(code)
    setInterval(() => {
      if (isTokenExpired()) refreshTokenAction()
    }, 10_000);
  } else {
    const refreshToken= getValueFromLocalStorage(localStorageConfig.REFRESH_TOKEN)
    if (refreshToken){
      refreshTokenAction()
    }else {
      redirectToLoginUrl(realmName)
    }
  }
}


//==================================================================================
export const redirectToLogoutUrl = (realmName) => {
  try {
    window.location.href = process.env.REACT_APP_LOGOUT_URL
      .replace('{realmName}', realmName || localStorage.getItem(localStorageConfig.REALM_NAME))
      .replace('{redirectUrl}', getCurrentUrl());
    window.localStorage.clear();
  } catch (err) {
    throw new Error(`Failed redirect to logout url, error: , ${err.message}`)
  }

}
export const redirectToLoginUrl = (realmName) => {

  try {
    window.location.href = process.env.REACT_APP_LOGIN_URL
      .replace('{realmName}', realmName || localStorage.getItem(localStorageConfig.REALM_NAME))
      .replace('{redirectUrl}', getCurrentUrl());
  } catch (err) {
    throw new Error(`Failed redirect to login url, error: , ${err.message}`)
  }
}


