import { NextApi } from 'src/redux/service/Next/next';

export const NOTIFICATION_EVENTS = 'NOTIFICATION_EVENTS';
export const MODAL_NOTIFICATION_EVENTS = 'MODAL_NOTIFICATION_EVENTS';

export const getClientDataEventsNext = new NextApi(`analytics/users`, NOTIFICATION_EVENTS, {
  method: 'GET', json: false,
});

export const getModalClientDataEventsNext = new NextApi(`analytics/users`, MODAL_NOTIFICATION_EVENTS, {
  method: 'GET', json: false,
});
