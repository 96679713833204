import {APP_SELECT_LANGUAGE, APP_SET, APP_SET_NAV_ITEMS, APP_SET_TOASTER_MSG} from "./actionTypes";
import {setValueToLocalStorage} from "../../../service/utils/utilFunctions";
import {localStorageConfig} from "../../../config";


export const toggleSidebar = (property, val) => ({type: APP_SET, [property]: val})
export const setToasterMsg = (msg) => {
  msg.id = Math.floor(Math.random() * 100)
  return {type: APP_SET_TOASTER_MSG, msg}
}

export const selectAppLanguage = (val) => {
  setValueToLocalStorage(localStorageConfig.SELECTED_LANGUAGE, val)
  return {type: APP_SELECT_LANGUAGE, selectedLang: val}
}
export const setNavigationItems = (navigationItemsSidebar) => ({type: APP_SET_NAV_ITEMS, navigationItemsSidebar})
