import React from 'react';
import {
  CDropdown, CDropdownMenu, CDropdownItem, CDropdownToggle
} from '@coreui/react'
import './FilterBy.scss'
import PropTypes from "prop-types";
import classnames from "classnames";
import { translate } from 'src/redux/service/i18n';


export const FilterBy =  React.memo(({selected, list, onFilterChange, label, filterId, customFilterClassName}) => {
  return (
    <div className={classnames(selected && "selected-filter", "filter-by", customFilterClassName)}>
      <CDropdown inNav className={"dropdown-filter-by"}>
        <CDropdownToggle>
          {list.find((item) => item?.value === selected)?.label || label}
          &nbsp;
        </CDropdownToggle>
        <CDropdownMenu className="dropdown-menu-filter-by">
          <CDropdownItem
            className={classnames(
              "all",
              selected ? "text-body-3" : "text-body-2"
            )}
            disabled={!selected}
            id={"not-selected"}
            key={"not-selected"}
            onClick={() => onFilterChange("", filterId)}
          >
            {translate("IensoTableFilters.All")}
          </CDropdownItem>
          {list?.map((item, index) => (
            <CDropdownItem
              id={item?.value}
              key={`${item?.value}_${index}`}
              disabled={selected === item?.value}
              className={classnames(
                selected === item?.value
                  ? "selected-item text-body-2"
                  : "text-body-3"
              )}
              onClick={(e) => onFilterChange(e.target.id, filterId)}
            >
              {item?.label}
            </CDropdownItem>
          ))}
        </CDropdownMenu>
      </CDropdown>
    </div>
  );
})

FilterBy.propTypes = {
  list: PropTypes.array,
  selected: PropTypes.string,
  onFilterChange: PropTypes.func,
  label: PropTypes.string,
  filterId: PropTypes.string
}
FilterBy.defaultProps = {
  list: [],
  selected: null,
  onFilterChange: () => undefined,
  label: 'Filter',
  filterId: 'filterId'
}
