import React from 'react';
import Translate from "src/redux/service/i18n";


const Dashboard = React.lazy(() => import("./views/dashboard/Dashboard"));

const UIComponents = React.lazy(() =>
  import("./views/UIComponents/UIComponents")
);

const WhiteLabelSettings = React.lazy(() =>
  import("./views/white-label-settings/WhiteLabelSettings")
);

const MessagingCenter = React.lazy(() =>
  import("./views/messaging-center/MessagingCenter")
);

const Operators = React.lazy(() =>
  import("./views/operators-management/operaors-main-page/Operators")
);

const OperatorsInfoPage = React.lazy(() =>
  import("./views/operators-management/operator-info-page/OperatorInfoPage")
);

const Clients = React.lazy(() =>
  import("./views/clients-management/clients-main-page/Clients")
);

const ClientInfoPage = React.lazy(() =>
  import("./views/clients-management/client-info-page/ClientInfoPage")
);

const DeviceSettings = React.lazy(() =>
  import("./views/device-settings-page/DeviceSettings")
);

const SoftwareManagement = React.lazy(() =>
  import("./views/software-management/SoftwareManagement")
);

const DeviceManagement = React.lazy(() =>
  import("./views/device-management/DeviceManagement")
);

const AuditLog = React.lazy(() => import("./views/audit-log/AuditLog"));

const PackageManagement = React.lazy(() =>
  import("./views/package-management/main-page/PackageManagement")
);

const CreatePackage = React.lazy(() =>
  import("./views/package-management/info-page/create-page/CreatePackage")
);

const CreatePackagePreview = React.lazy(() =>
  import("./views/package-management/info-page/preview-page/PreviewPage")
);

const PackageComparison = React.lazy(() =>
  import(
    "./views/package-management/info-page/package-comparison/PackageComparison"
  )
);

const HelpPage = React.lazy(() => import("./views/help-page/HelpPage"));

const SecurityInsights = React.lazy(() =>
  import("./views/security-insights/SecurityInsights")
);

const AnalyticsManagement = React.lazy(() =>
  import("./views/analytics-management/AnalyticsManagement")
);

const DeviceDatabase = React.lazy(() =>
  import("./views/device-database/DeviceDatabase")
);

const CampaignManagement = React.lazy(() =>
  import("./views/campaign-management/CampaignManagement")
);

const CreateCampaign = React.lazy(() =>
  import("./views/campaign-management/components/create-page/CreateCampaign")
);

//====================================
export const DASHBOARD = "/dashboard"
export const ACCOUNT_INFO = "/accountInfo"
export const OPERATORS = "/operators"
export const CLIENTS = "/clients"
export const MESSAGING_CENTER = '/messaging-center'
export const ANALYTICS_REPORT = '/analytics-report'
export const PACKAGE_MANAGEMENT = '/package-management'
export const DEVICE_MANAGEMENT = '/device-management'
export const SOFTWARE_MANAGEMENT = '/software-management'
export const WHITE_LABEL_SETTINGS = '/whitelabel-settings'
export const AUDIT_LOG = "/audit-log"
export const UI_COMPONENTS = "/ui-components"
export const DEVICE = '/device'
export const SECURITY_INSIGHTS = '/security-insights';
export const ANALYTICS_MANAGEMENT = '/analytics-management';
export const DEVICE_DATABASE = '/device-database';
export const CAMPAIGN_MANAGEMENT = '/campaign-management';
export const CUSTOMER_INSIGHTS='/customer-insights'


export const USERS = "/users"
export const SETTINGS = "/settings"
export const INFO = "/info"
export const CREATE = "/create"
export const PREVIEW = "/preview"
export const HELP = '/help'
export const PACKAGE_COMPARISON = '/package-comparison'
//====================================
export const LOGIN = '/login'
export const LOGOUT = '/logout'
export const REGISTER = '/register'
export const ERROR_404 = '/404'
export const ERROR_500 = '/500'

const routes = [
  {
    path: '/', exact: true, name: <Translate textKey={
      "Dashboard.Title"
    }
    />, id: ''
  },
  {
    path: HELP, exact: true, component: HelpPage, name: <Translate textKey={
      "Help.Title"
    }
    />, id: 'help'
  },
  {
    path: DASHBOARD, name: <Translate textKey={
      "Dashboard.Title"
    }
    />, component: Dashboard, id: 'dashboard'
  },
  {
    path: UI_COMPONENTS, name: <Translate textKey={
      "UiComponents.Title"
    }
    />, component: UIComponents, id: 'ui-components'
  },
  {
    path: MESSAGING_CENTER, exact: true, name: <Translate textKey={
      "MessagingCenter.Title"
    }
    />, component: MessagingCenter, id: 'messaging-center'
  },
  {
    path: OPERATORS, exact: true, name: <Translate textKey={
      "OperatorManagement.Title"
    }
    />, component: Operators, id: 'operators-management'
  },
  {
    path: `${OPERATORS + CREATE}`, exact: false, name: <Translate textKey={
      "OperatorManagement.AddNewOperator"
    }
    />, component: OperatorsInfoPage, id: 'operator-create'
  },
  {
    path: `${OPERATORS}/:operatorId${INFO}`, exact: false, name: <Translate textKey={
      "OperatorManagement.EditOperator"
    }
    />, component: OperatorsInfoPage, id: 'operator-info'
  },
  {
    path: CLIENTS, exact: true, name: <Translate textKey={
      "ClientsManagement.Title"
    }
    />, component: Clients, id: 'clients-management'
  },
  {
    path: `${CLIENTS}/:clientId${INFO}`, exact: false, name: <Translate textKey={
      "ClientsManagement.ClientDetails"
    }
    />, component: ClientInfoPage, id: 'client-details'
  },

  {
    path: WHITE_LABEL_SETTINGS, exact: true, name: <Translate textKey={
      "WhiteLabelSettings.Label"
    }
    />, component: WhiteLabelSettings, id: 'white-label-settings'
  },
  {
    path: SOFTWARE_MANAGEMENT, exact: true, name: <Translate textKey={
      "SoftwareManagement.Title"
    }
    />, component: SoftwareManagement, id: 'software-management'
  },
  {
    path: DEVICE_MANAGEMENT, exact: true, name: <Translate textKey={
      "DeviceManagement.Title"
    }
    />, component: DeviceManagement, id: 'device-management'
  },
  {
    path: `${DEVICE_MANAGEMENT}/:deviceId${INFO}`, exact: false, name: <Translate textKey={
      "DeviceManagement.DeviceSettings"
    }
    />, component: DeviceSettings, id: 'device-settings'
  },

  {
    path: PACKAGE_MANAGEMENT, exact: true, name: <Translate textKey={
      "PackageManagement.PackageManagement"
    }
    />, component: PackageManagement, id: 'package-management'
  },

  {
    path: `${PACKAGE_MANAGEMENT + CREATE}`, exact: true, name: <Translate textKey={
      "PackageManagement.CreatePackage"
    }
    />, component: CreatePackage, id: 'create-package'
  },

  {
    path: `${PACKAGE_MANAGEMENT}/:packageId${CREATE}`, exact: true, name: <Translate textKey={
      "PackageManagement.EditPackage"
    }
    />, component: CreatePackage, id: 'edit-package'
  },

  {
    path: `${PACKAGE_MANAGEMENT + CREATE + PREVIEW}`, exact: true, name: <Translate textKey={
      "PackageManagement.Preview"
    }
    />, component: CreatePackagePreview, id: 'create-package-preview'
  },

  {
    path: `${PACKAGE_MANAGEMENT + PACKAGE_COMPARISON}`, exact: true, name: <Translate textKey={
      "PackageManagement.PackageComparison"
    }
    />, component: PackageComparison, id: 'package-comparison'
  },

  {
    path: `${AUDIT_LOG}`, exact: false, name: <Translate textKey={
      "AuditLog.Title"
    }
    />, component: AuditLog, id: 'audit-log'
  },

  {
    path: SECURITY_INSIGHTS, exact: true, name: <Translate textKey={
      "SecurityInsights.Title"
    }
    />, component: SecurityInsights, id: 'security-insights'
  },

  {
    path: ANALYTICS_MANAGEMENT, exact: true, name: <Translate textKey={
      "AnalyticsManagement.Title"
    }
    />, component: AnalyticsManagement, id: 'analytics-management'
  },

  {
    path: DEVICE_DATABASE, exact: true, name: <Translate textKey={
      "DeviceDatabase.Title"
    }
    />, component: DeviceDatabase, id: 'device-database'
  },

  {
    path: CAMPAIGN_MANAGEMENT, exact: true, name: <Translate textKey={
      "CampaignManagement.title"
    }
    />, component: CampaignManagement, id: 'campaign-management'
  },

  {
    path: `${CAMPAIGN_MANAGEMENT + CREATE}`, exact: true, name: <Translate textKey={
      "CampaignManagemen.CreateNewCampaign.Title"
    }
    />, component: CreateCampaign, id: 'campaign-create'
  },

];


export default routes;
