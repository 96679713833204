import {selectAppLanguage, setToasterMsg} from "../../redux/service/app/actions";
import { translate } from "src/redux/service/i18n";
import {store} from "../../index";
import {localStorageConfig} from "../../config";
import {redirectToLogoutUrl} from "./keycloak/utilFunstions";
import moment from "moment";

export const cutText = (text, limit = 65) => {
  if (text.length > limit) {
    return text.slice(limit) + ".."
  }
  return text;
}

export const deleteHtmlTags = (text) => {
  const doc = new DOMParser().parseFromString(text, 'text/html');
  return doc.body.textContent || "";
}


export const getCurrentUrl = () => `${window.location.protocol}//${window.location.host}/operator/`


export const getAppLangFromLocalStorage = () => {
  const appLanguageRedux = store.getState()?.realmInfo?.whitelabelInfo?.language
  const langInLocalStorage = getValueFromLocalStorage(localStorageConfig.SELECTED_LANGUAGE)
  if (langInLocalStorage && appLanguageRedux !== langInLocalStorage) {
    store.dispatch(selectAppLanguage(langInLocalStorage))
  } else {
    store.dispatch(selectAppLanguage(appLanguageRedux))
  }
}


export const toastColor = {
  SUCCESS: 'success',
  DANGER: 'danger',
  WARNING: 'warning'
}
export const showToast = (color = toastColor.DANGER,
                          text = translate('Toast.Error')) => {
  store.dispatch(setToasterMsg({color, text}))
}


export const setValueToLocalStorage = (key, val, isJson = false) => window.localStorage.setItem(key, isJson ? JSON.stringify(val) : val)

export const getValueFromLocalStorage = (key, isJson = false) => {
  try {
    if (isJson) {
      return JSON.parse(window.localStorage.getItem(key))
    } else {
      return window.localStorage.getItem(key)
    }
  } catch (e) {
    console.error('Failed to get from storage key:', key)
    console.error('Value', window.localStorage.getItem(key))
    console.error(e);
    return {};
  }
}

export const convertStrArrayToObjArray = (arr) => arr?.map((item) => ({text: item, value: item}))

export const getRoles = (tokenData) => {
  const accessRoles = tokenData?.realm_access?.roles
  let res = {}
  if (accessRoles) {
    for (const accessRole of accessRoles) {
      if (accessRole.includes('@')) {
        let splited = accessRole.split('@')
        res[splited[0]] = {...res[splited[0]], [splited[1]]: true}
      }
    }
  }
  return res;
}

export const formatUserRoles = (roles) => {
  let res = {}
  if (roles) {
    for (const role of roles) {
      if (role.name.includes('@')) {
        let splited = role.name.split('@')
        res[splited[0]] = {...res[splited[0]], [splited[1]]: true}
      }
    }
  }
  return res;
}


export const errorHandler = (error) => {
  switch (error) {
    case 401:
      redirectToLogoutUrl()
      break;
    default:
      break;
  }
}
export const numberFormat = (num) => num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const removeItemsWithSameId = (arr) => {
  let obj = {}
  for (const item of arr) {
    obj[item.id] = item
  }
  return Object.values(obj)
}

export const formatDate = (date, formatOutput = "DD/MM/YY", formatInput = 'DD/MM/YYYY') => moment(moment(date, formatInput)).format(formatOutput);

export const removeEmptyFields = (obj) => {
  for (const objKey in obj) {
    if (obj[objKey] === '' || obj[objKey] == null) delete obj[objKey]
  }
}

export const createStrArrFromObjArr = (arr) => arr?.map(item => item.value)


const getPeriod = (periodVal) => {
  switch (periodVal) {
    case 'D':
      return translate('Day')
    case 'W':
      return translate('Week')
    case 'M':
      return translate('Month')
    case 'Y':
      return translate('Year')
    default:
      return ''
  }
}

export const convertServicePlan = (amount, period) => {
  if (!amount || !period) {
    return '-';
  }

  return `${amount}/ ${getPeriod(period)}`;
}


export const convertPeriodToNormalView = (amount, period) => {
  if (amount) {
    let convertedPeriod = getPeriod(period)
    convertedPeriod = Number(amount) > 1 ? convertedPeriod + "s" : convertedPeriod
    return `${amount} ${convertedPeriod}`
  } else {
    return '-'
  }
}

export const findObjInArrayByValue = (arr, val) => arr && arr?.find(item => val === item?.value)

export const shortText = (text, limit = 13) => {
  if (text.length > limit) return text.slice(0, limit - 1) + '...'
  return text
}


export const preventSubmitFormikByPressEnter = (keyEvent) => {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
}

//determine one slot value
export const getSlotValue = (featureType, packageItem, featureSystemName) => {
  switch (featureType) {
    case "TITLE":
      return " ";

    case "AI_FEATURE":
      return packageItem[`aiFeatures`]?.includes(featureSystemName)
        ? true
        : "-";

    case "DEVICE_FEATURE":
      return packageItem[`deviceFeatures`]?.includes(featureSystemName)
        ? true
        : "-";

    default: {
      switch (featureSystemName) {
        case "servicePlan":
          return convertServicePlan(
            packageItem.servicePlanPeriodAmount,
            packageItem.servicePlanPeriodTime
          );

        case "duration": 
          return convertPeriodToNormalView(
            packageItem.durationPeriodAmount,
            packageItem.durationPeriodTime
          );

        case "cloudStorage":
          const cloudStorageObj = {
            days: packageItem.cloudStoragePeriod
              ? `${packageItem.cloudStoragePeriod} ${translate('PackageManagement.Compare.days')}`
              : "-",
            space: packageItem.cloudStorageSpace
              ? `${packageItem.cloudStorageSpace} ${translate('PackageManagement.Compare.GB')}`
              : "-",
          };
          return cloudStorageObj;

        case "nDevices":
          if (packageItem.nDevicesIsUnlimited) {
            return translate('PackageManagement.Compare.Unlimited');
          }

          return `${translate('PackageManagement.Compare.Upto')} ${packageItem[featureSystemName]}`;
        
        default:
          return packageItem[featureSystemName] || "-";
      }
    }
  }
};
