import React from 'react';
import {
  CFormGroup,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CInvalidFeedback,
   CLabel
} from "@coreui/react";
import './IensoColorSelector.scss'
import PropTypes from "prop-types";
import {useField} from "formik";
import classnames from "classnames";
import '../IensoInputsGroup.scss'
import { IensoTooltip } from '../../IensoTooltip/IensoTooltip';

export const IensoColorSelector =  React.memo(({label, id,disabled, useTooltip, tooltipText, tooltipIcon,  ...props}) => {
  const [field, meta] = useField(props);

  return (
    <div className={classnames('ienso-color-selector', disabled && 'ienso-color-selector-disabled' )} id={id}>
      <CFormGroup>
        <CLabel>
          {label}
          {useTooltip && (
            <IensoTooltip text={tooltipText}>{tooltipIcon}</IensoTooltip>
          )}
        </CLabel>
        <CInputGroup>
          <CInputGroupPrepend>
            <CInputGroupText className={classnames('ienso-color-selector__icon-container',props?.invalid && 'input-group-text-invalid')}>
              <div className={'color-view-wrapper'}>
                <input className={'color-view'} disabled={disabled} type="color"  {...field} {...props}/>
              </div>
            </CInputGroupText>
          </CInputGroupPrepend>
          <CInput
            id={id}
            disabled={disabled}
            {...field} {...props}
          />
          <CInvalidFeedback>{meta?.error}</CInvalidFeedback>
        </CInputGroup>
      </CFormGroup>
    </div>
  );
})

IensoColorSelector.propTypes = {
  onChange: PropTypes.func,
  id: PropTypes.string,
  label: PropTypes.string,
  useTooltip: PropTypes.bool,
  tooltipText: PropTypes.string,
  tooltipIcon: PropTypes.node
}
IensoColorSelector.defaultProps = {
  onChange: () => undefined,
  id: '',
  label: '',
  useTooltip: false,
  tooltipText: '',
  tooltipIcon: null
}
